/* eslint-disable */
import axios from "axios";
import md5 from 'js-md5';
let BackUrl = null;
let BasiUrl  = process.env.NODE_ENV;
if(BasiUrl === 'development') {
  BackUrl = '/api'
}else if(BasiUrl === 'production') {
  BackUrl = 'https://gdgl.zcbds.com/api/'
}

axios.defaults.baseURL=BackUrl;
// 2.请求拦截器
axios.interceptors.request.use(config => {
  //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
  // console.log(config);
  // console.log(config.data);
  // config.data = {
  //   name:'000',
  //   sex:'111'
  // }
  // config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
  // config.headers = {
  //   "Content-Type": "application/json" //配置请求头
  // };
  //const token = sessionStorage.getItem('');//这里取token之前，你肯定需要先拿到token,存一下
  //if(token){
  //config.params = {'token':token} //如果要求携带在参数中
  //config.headers.token= token; //如果要求携带在请求头中
  //}
  return config;
}, error => {
  Promise.reject(error);
});
/**
 *自定义方法
 * @param {string} type 类型 'get' 或 'post'
 * @param {string} url 请求地址
 * @param {string}keyword 关键字
 * @param {object} data 发送的数据
 * @param {string}classify ‘01’ PC端 ‘02’ APP ‘03’小程序
 * @returns {Promise<unknown>} 返回请求相应
 */
function vaildInterfaceW(type,url,keyword,data,classify='01'){
  return new Promise((resolve,reject)=>{
    var datestr = (new Date()).valueOf();
    data.keyword = keyword;
    var sign = keyword + JSON.stringify(data) + classify;
    // var param = {
    //   d: JSON.stringify(data),
    //   v: md5(sign),
    //   f: classify,
    //   t: datestr
    // }
        var formData = new FormData();
		formData.append("d",JSON.stringify(data));
		formData.append("v",md5(sign));
		formData.append("f",classify);
		formData.append("t",datestr);
    var obj = {
      method: type, //请求的类型
      url:url, //请求地址
      data:formData
    }
    //返回axios的基础方法
     axios(obj).then(res => {
       resolve(res.data)
    }).catch(error => {
       reject(error)
       });
  });
}
/**
 *自定义方法 文件上传
 * @param {string} type 类型 'get' 或 'post'
 * @param {string} url 请求地址
 * @param {string}keyword 关键字
 * @param {object} data 发送的数据
 * @param {object}file 文件
 * @param {string}classify ‘01’ PC端 ‘02’ APP ‘03’小程序
 * @returns {Promise<unknown>} 返回请求相应
 */
function vaildInterfaceUploadFileW(type,url,keyword,data,file,classify='01'){
    return new Promise((resolve,reject)=>{
        var datestr = (new Date()).valueOf();
        data.keyword = keyword;
        var sign = keyword + JSON.stringify(data) + classify;
        // var param = {
        //   d: JSON.stringify(data),
        //   v: md5(sign),
        //   f: classify,
        //   t: datestr
        // }
        var formData = new FormData();
        formData.append("d",JSON.stringify(data));
        formData.append("v",md5(sign));
        formData.append("f",classify);
        formData.append("t",datestr);
        formData.append("file",file);
        var obj = {
            method: type, //请求的类型
            url:url, //请求地址
            data:formData
        }
        //返回axios的基础方法
        axios(obj).then(res => {
            resolve(res.data)
        }).catch(error => {
            reject(error)
        });
    });
}
export { vaildInterfaceW , vaildInterfaceUploadFileW};

