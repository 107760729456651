<template>
  <router-view/>
</template>

<style>
*{
  margin: 0;
  padding: 0;
}
html,body{
  width: 100%;
  height: 100%;
}
#app{
  width: 100%;
  height: 100%;
}
.login-bg input{
  font-size: 24px;
  padding: 15px 40px;
  box-sizing: border-box;
  color: #FFFFFF;
  min-height: 50px;
}
.login-bg .el-input__wrapper{
  border-radius: 12px;
  padding: 0;
  overflow: hidden;
  background: rgba(255, 255, 255 ,.3);
  box-shadow:none;
}
.blue-text{
  color: #3A75FF;
}
/*开发指南样式*/
.exampleValue{
  margin: 0 2px;
  padding: 4px 8px;
  background-color: #fafafa;
  border-radius: 4px;
}
</style>
