import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {vaildInterfaceUploadFileW, vaildInterfaceW} from "./axios";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
//自定义封装axios方法
app.config.globalProperties.vaildInterfaceW = vaildInterfaceW;
app.config.globalProperties.vaildInterfaceUploadFileW = vaildInterfaceUploadFileW;
app.use(store).use(router).use(ElementPlus, {
    locale: zhCn,
}).mount('#app')

if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
}
// 前置路由守卫
router.beforeEach((to, from, next) => {
    if (sessionStorage.getItem('ucode') || to.name ==='login') {
        next();
    }else{
        next('/');
    }
})
