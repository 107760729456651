import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/home',
    name: 'home',
    redirect:'/home/homeIndex',
    component: () => import('../views/HomeView.vue'),
    children:[
      {
        path: 'homeIndex',
        name: 'homeIndex',
        component: () => import('../views/homeIndex.vue')
      },{
        path: 'userManagement',
        name: 'userManagement',
        component: () => import('../views/userManagement.vue')
      },{
        path: 'projectManagement',
        name: 'projectManagement',
        component: () => import('../views/projectManagement.vue')
      },{
        path: 'workOrderManagement',
        name: 'workOrderManagement',
        component: () => import('../views/workOrderManagement.vue')
      },{
        path: 'handlingWorkOrder',
        name: 'handlingWorkOrder',
        component: () => import('../views/handlingWorkOrder.vue')
      },{
        path: 'developerSGuide',
        name: 'developerSGuide',
        component: () => import('../views/developerSGuide.vue')
      },{
        path: 'processingManagement',
        name: 'processingManagement',
        component: () => import('../views/processingManagement.vue')
      },{
        path: 'workListForMy',
        name: 'workListForMy',
        component: () => import('../views/workListForMy.vue')
      },{
        path: 'workOrderDetail',
        name: 'workOrderDetail',
        component: () => import('../views/workOrderDetail.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
